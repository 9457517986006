<template>
  <button
    v-if="!url"
    class="button"
    :disabled="disabled"
    :class="{
      'button--green': cta === 'green',
      'button--primary': cta === 'primary',
      'button--secondary': cta === 'secondary',
      'button--icon': icon,
      'button--transparent': cta === 'transparent',
    }"
    @click="$emit('clicked', index !== undefined ? { index: index, value: value ? value : '' } : value ? value : '')"
  >
    <slot />
  </button>

  <router-link
    v-else
    :tag="newWindow ? 'a' : 'button'"
    :to="newWindow ? '' : url"
    :href="url ? url : ''"
    :target="newWindow ? '_blank' : ''"
    class="button"
    :class="{
      'button--primary': cta === 'primary',
      'button--secondary': cta === 'secondary',
      'button--icon': icon,
      'button--transparent': cta === 'transparent',
    }"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  name: "Button",

  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    newWindow: {
      type: Boolean,
    },
    url: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: null,
    },
    cta: {
      type: String,
      default: "",
    },
    icon: {
      type: Boolean,
    },
    showConfirm: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import "../styles/colors";
@import "../styles/globals";
@import "../styles/fonts";

.button {
  display: flex;
  cursor: pointer;
  height: 2.5rem;
  align-items: center;
  font-weight: 600;
  padding: 0;
  flex-grow: 0;
  text-align: left;
  outline: 0;
  background: transparent;
  white-space: nowrap;
  font-family: $primary-font-stack;
  border: 0;
  color: color(blue, light);
  font-size: 1rem;
  transition: var(--transition-in);

  &:hover {
    color: color(white, base);
  }

  &:active {
    top: 1px;
  }

  .icon:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.button--primary,
.button--secondary,
.button--green {
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  border-radius: 3px;
  padding: 0 1.5rem;
  color: color(white, base);

  &:hover {
    color: color(white, base);
  }
}

.button--secondary {
  border: 1px solid color(blue, light);
  color: color(blue, light);

  &:hover {
    color: color(white, base);
  }
}

.button--primary {
  background-color: rgba(52, 198, 110, 0.1);
  color: color(cta, primary);
  border: 1px solid color(cta, primary);

  &:hover {
    color: color(white, base);
  }
}

.button--green {
  background-color: color(signal, green);
  color: color(white, base);

  &:hover {
    box-shadow: 0 0 10px color(green, signal);
  }
}

.button--link {
  color: color(cta, primary);
}

.button--transparent {
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  padding: 0 1.5rem;
  background-color: transparent;
  border: 1px solid color(border, base);
  border-radius: 3px;

  &:hover {
    border: 1px solid color(white, base);
  }

  &.active {
    border: 1px solid color(cta, primary);
    color: color(cta, primary);
  }
}

.button--icon {
  .icon:last-child {
    margin: 0 -1.5rem;
  }
}
</style>
