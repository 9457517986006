var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.url)?_c('button',{staticClass:"button",class:{
    'button--green': _vm.cta === 'green',
    'button--primary': _vm.cta === 'primary',
    'button--secondary': _vm.cta === 'secondary',
    'button--icon': _vm.icon,
    'button--transparent': _vm.cta === 'transparent',
  },attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('clicked', _vm.index !== undefined ? { index: _vm.index, value: _vm.value ? _vm.value : '' } : _vm.value ? _vm.value : '')}}},[_vm._t("default")],2):_c('router-link',{staticClass:"button",class:{
    'button--primary': _vm.cta === 'primary',
    'button--secondary': _vm.cta === 'secondary',
    'button--icon': _vm.icon,
    'button--transparent': _vm.cta === 'transparent',
  },attrs:{"tag":_vm.newWindow ? 'a' : 'button',"to":_vm.newWindow ? '' : _vm.url,"href":_vm.url ? _vm.url : '',"target":_vm.newWindow ? '_blank' : ''}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }